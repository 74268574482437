import { Grid, GridProps, GridSize, makeStyles, Theme, Typography, TypographyProps } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { SuccessIcon } from "./icons";

const useStyles = makeStyles((theme: Theme) => ({
  successColor: {
    color: theme.palette.success.main,
  },
  textAlignEnd: {
    textAlign: "end",
  },
  textWrap: {
    wordBreak: "break-word",
    marginBottom: ".25rem",
  },
  dl: {
    margin: 0,
  },
  dd: {
    "margin-inline-start": 0,
  },
}));

type Value = {
  value: string | Date | React.ReactNode;
  valueColor?: TypographyProps["color"] | "success";
  valueAlign?: TypographyProps["align"];
  valueVariant?: TypographyProps["variant"];
  textAlignEnd?: boolean;
};

type Props = GridProps & {
  id: string;
  label?: string;
  labelVariant?: TypographyProps["variant"];
  values?: Value[];
  labelColor?: TypographyProps["color"] | "success";
  valueClass?: string;
  labelClass?: string;
  valueSuccessIcon?: boolean;
  labelGrid?: GridSize;
  valueGrid?: GridSize;
  direction?: GridProps["direction"];
  wrap?: GridProps["wrap"];
  nullFiller?: string;
};

const CardField: React.FC<Props> = ({
  id,
  label,
  values,
  className,
  labelColor,
  labelVariant,
  valueClass,
  labelClass,
  valueSuccessIcon,
  labelGrid,
  valueGrid,
  direction,
  wrap,
  nullFiller = "",
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.dl} container direction={direction} wrap={wrap} component={values ? "dl" : "div"}>
      {label && (
        <Grid
          id={id}
          component={values ? "dt" : "div"}
          item
          xs={labelGrid ? labelGrid : 6}
          className={classNames(className, labelClass, classes.textWrap)}
        >
          <Typography
            variant={labelVariant ? labelVariant : "h4"}
            component="p"
            color={labelColor && labelColor !== "success" ? labelColor : "textSecondary"}
            className={classNames({ [classes.successColor]: labelColor === "success" })}
          >
            {label}
          </Typography>
          {valueSuccessIcon && <SuccessIcon className={classes.successColor} />}
        </Grid>
      )}
      {values && (
        <Grid
          item
          container
          xs={valueGrid ? valueGrid : 6}
          className={classNames(classes.dd, className, valueClass, classes.textWrap)}
          direction="column"
          component={"dd"}
          aria-labelledby={id}
        >
          {values.map((value: Value, index: number) => {
            return (
              <Grid item key={`${value.value}-${index}`}>
                <Typography
                  align={value.valueAlign}
                  color={value.valueColor && value.valueColor !== "success" ? value.valueColor : undefined}
                  variant={value.valueVariant ? value.valueVariant : "h4"}
                  component="p"
                  className={classNames({
                    [classes.successColor]: value.valueColor === "success",
                    [classes.textAlignEnd]: value.textAlignEnd,
                  })}
                >
                  {value.value ? value.value : nullFiller}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

export default CardField;
