/* eslint-disable @typescript-eslint/no-empty-interface */
import { createMuiTheme, fade, lighten } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";
import {
  backgroundGrey,
  black,
  blue,
  cardGrey,
  dark,
  darkBlue,
  darkerGrey,
  darkGreen,
  darkGrey,
  extraLightGrey,
  green,
  grey,
  iconsGrey,
  inactiveTextGrey,
  inputBorderGrey,
  lightBlue,
  lighterBlue,
  lightishBlue,
  orange,
  red,
  white,
} from "./colors";
import { bold, normal } from "./fonts";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const sourceSansProFamily = "'Source Sans Pro', Helvetica, Arial, sans-serif";
const notoSansFamily = "'Noto Sans', Helvetica, Arial, sans-serif";
const breakpoints = createBreakpoints({});

const typography: TypographyOptions = {
  fontFamily: ["Source Sans Pro", "Noto Sans", "Helvetica", "Arial", "sans-serif"].join(","),
  allVariants: {
    color: dark,
  },
  h1: {
    fontFamily: sourceSansProFamily,
    fontWeight: bold,
    fontSize: "2rem",
    lineHeight: "2.5rem",
    letterSpacing: "0.4px",
  },
  h2: {
    fontFamily: sourceSansProFamily,
    fontWeight: bold,
    fontSize: "1.125rem",
    lineHeight: "1.375rem",
    letterSpacing: "0.4px",
  },
  h3: {
    fontFamily: notoSansFamily,
    fontWeight: normal,
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    letterSpacing: "0.4px",
  },
  h4: {
    fontFamily: sourceSansProFamily,
    fontWeight: bold,
    fontSize: "1rem",
    lineHeight: "1rem",
  },
  body1: {
    fontFamily: notoSansFamily,
    fontWeight: normal,
    fontSize: "1rem",
    lineHeight: "1.25rem",
  },
  subtitle1: {
    fontFamily: notoSansFamily,
    fontWeight: normal,
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
  },
  button: {
    fontFamily: sourceSansProFamily,
    fontWeight: bold,
  },
};

const defaultTheme = createMuiTheme({
  palette: {
    common: {
      black,
      white,
    },
    background: {
      default: extraLightGrey,
    },
    action: {
      disabled: lighterBlue,
      active: lightishBlue,
    },
    grey: {
      "100": grey,
      "200": extraLightGrey,
      "300": iconsGrey,
      "400": inactiveTextGrey,
      "500": inputBorderGrey,
      "600": cardGrey,
      "700": darkGrey,
      "800": darkerGrey,
    },
    primary: {
      main: blue,
      light: backgroundGrey,
      dark: darkBlue,
    },
    secondary: {
      main: dark,
      contrastText: lightBlue,
      light: grey,
    },
    error: {
      main: red,
      light: orange,
    },
    success: {
      main: green,
      dark: darkGreen,
    },
  },
  typography,
  props: {
    MuiTextField: {
      variant: "outlined",
      margin: "normal",
      InputLabelProps: { shrink: true },
      InputProps: { notched: false },
    },
    MuiFormControl: {
      variant: "outlined",
      margin: "normal",
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiOutlinedInput: {
      notched: false,
    },
    MuiCheckbox: {
      color: "primary",
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: white,
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: sourceSansProFamily,
        fontSize: "0.875rem",
        fontWeight: bold,
        lineHeight: "1.125rem",
        letterSpacing: "0.4px",
        color: dark,
        width: "100%",
      },
      outlined: {
        "&.MuiInputLabel-shrink": { transform: "translate(0rem, 0rem) scale(1)" },
        "&.Mui-error": {
          color: dark,
        },
        "&.Mui-required": {
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
          width: "auto",
          "& .MuiInputLabel-asterisk": {
            color: blue,
            fontSize: "1.25rem",
            transform: "translate(-0.25rem, 0.25rem);",
          },
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        [breakpoints.up("md")]: {
          padding: "12.875px 14px",
        },
        padding: "12.875px 8px",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: `0px 1px 4px ${fade(black, 0.1)}`,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: "1.5rem",
        marginBottom: "0.75rem",
      },
    },
    MuiFormControlLabel: {
      label: {
        paddingLeft: "0.5rem",
        "& .MuiGrid-root + .MuiTypography-root": {
          color: lighten(darkGrey, 0.46),
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        ...typography.h2,
        "&$disabled": {
          "& .MuiTypography-root": {
            color: inactiveTextGrey,
          },
        },
      },
      contained: {
        backgroundColor: blue,
        color: white,
        textTransform: "none",
        ...typography.h2,
        "&:hover": {
          backgroundColor: grey,
          color: inactiveTextGrey,
        },
        "&$disabled": {
          backgroundColor: grey,
          color: inactiveTextGrey,
        },
        "& .MuiTypography-root": {
          color: white,
        },
      },
    },
    MuiIconButton: {
      root: {
        "&$disabled": {
          color: inactiveTextGrey,
        },
      },
    },
    MuiAccordion: {
      root: {
        "&$expanded": {
          margin: "auto",
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: "auto",
        "&$expanded": {
          margin: "auto",
        },
      },
    },
    MuiStepIcon: {
      root: {
        position: "relative",
        top: "4px",
        left: "4px",
      },
    },
    MuiStepLabel: {
      root: {
        "& .MuiTypography-root": {
          textTransform: "uppercase",
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        "& .MuiButton-root": {
          "&:hover": {
            backgroundColor: fade(black, 0.1),
          },
        },
        "& .MuiTypography-subtitle1": {
          fontSize: "1rem",
          padding: "0.5rem 0rem",
        },
        "& .MuiTypography-h4": {
          fontSize: "2.125rem",
          lineHeight: "1.17",
        },
      },
    },
  },
});

export default defaultTheme;
