export const black = "#000000";
export const white = "#ffffff";
export const dark = "#31353A";
export const cardGrey = "#F9F9F9";
export const darkGrey = "#263238";
export const lightGrey = "#F8F8F8";
export const extraLightGrey = "#F2F2F2";
export const backgroundGrey = "#FAFAFA";
export const blue = "#2346AA";
export const lightBlue = "#C2D7EC";
export const lighterBlue = "#E8EDF1";
export const lightishBlue = "#D4E2EE";
export const darkBlue = "#273449";
export const red = "#CC0000";
export const darkRed = "#800000";
export const orange = "#B96F01";
export const grey = "#D7D8D9";
export const inactiveTextGrey = "#9CA2A8";
export const darkerGrey = "#7E8082";
export const green = "#0E9F11";
export const darkGreen = "#017C03";
export const inputBorderGrey = "#C6C8C9";
export const iconsGrey = "#979797";
